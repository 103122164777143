@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,500;0,700;0,900;1,400;1,700&display=swap');
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?bnfuxy');
  src: url('../fonts/icomoon.eot?bnfuxy#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?bnfuxy') format('truetype'),
    url('../fonts/icomoon.woff?bnfuxy') format('woff'),
    url('../fonts/icomoon.svg?bnfuxy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tools:before {
  content: '\e900';
}
.icon-tools-2:before {
  content: '\e901';
}

@import '_reset';
@import '_variables';
@import '_element-ui';
@import '_header';
@import '_helpers';
@import '_sidebar';
@import '_panel';
@import '_posts';
@import '_navigation';

html,
body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: $dark-100;
  min-height: 100%;
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  background-color: $bg;
  &.theme-dark {
    background-color: $dark;
    color: #fff;
  }
}

.icon-tools:before {
  content: '\e900';
}

a {
  color: $primary;
}

.gn-title {
  font-size: 30px;
  font-weight: 900;
}
.gn-subtitle {
  font-size: 17px;
  color: #7993ad;
  margin-top: 10px;
}

.gn-list {
  li {
    display: block;
    margin-bottom: 20px;
    label {
      display: block;
      font-size: 11px;
      text-transform: uppercase;
      color: $dark-300;
    }
    span {
      margin-top: 5px;
      display: block;
      font-size: 15px;
      letter-spacing: 0.4px;
      color: $dark-100;
    }
  }
}

.applied-filters {
  margin-bottom: 20px;
  .applied-filter {
    margin: 5px 0;
  }
  .applied-filter + .applied-filter {
    margin-left: 5px;
  }
}

.verified-profile-badge {
  display: inline-block;
  width: 17px;
  height: 17px;
  line-height: 17px;
  text-align: center;
  margin-left: 5px;
  vertical-align: middle;
  font-size: 10px;
  border-radius: 99px;
  background-color: #409eff;
  border-color: #409eff;
  color: white;
}

.gn-table {
  width: 100%;
  thead {
    th {
      color: #909399;
      padding: 12px 0;
    }
  }
  tbody {
    tr {
      position: relative;
      td {
        padding: 12px 0;
      }
      .tr-overlay {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(245, 247, 250, 0.5);
        font-size: 20px;
        z-index: 2;
        span {
          padding: 5px;
          background-color: #fafbfc;
        }
      }
    }
  }
  tr {
    border-bottom: 1px solid #ebeef5;
  }
}
.el-table .cell {
  word-break: keep-all !important;
}