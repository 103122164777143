#sidebar {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 70px;
  height: 100vh;
  background-color: $dark-100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sidebar-footer {
    text-align: center;
    color: #9d9d9d;
    padding-bottom: 20px;
  }
  #menu {
    margin: 80px 0 0 0;
    li {
      margin: 0;
      padding: 0;
      a {
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        color: #fff;
        text-align: center;
        position: relative;
        span {
          position: absolute;
          left: 70px;
          top: 0;
          display: block;
          width: auto;
          max-width: 0px;
          overflow: hidden;
          transition: all ease 0.2s;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          background-color: #081623;
          transition: all ease 0.2s;
          white-space: nowrap;
        }
        &:hover {
          background-color: #081623;
          span {
            max-width: 500px;
            padding: 0 20px;
          }
        }
        &.router-link-active {
          background-color: #409eff;
          span {
            background-color: #409eff;
          }
        }
      }
    }
  }
}

// .theme-dark {
//   #sidebar {
//     background-color: #fff;
//     color: $dark;
//     #menu li a {
//       color: $dark;
//     }
//   }
// }
