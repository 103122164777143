.items-right {
  display: flex;
  justify-content: end;
}

.items-center {
  display: flex;
  justify-content: center;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textual-error {
  font-size: 16px;
  text-align: center;
  color: #d23131;
  margin-bottom: 20px;
}

.text-right {
  text-align: right;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-5 {
  margin-bottom: 5px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-15 {
  margin-top: 15px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.text-center {
  text-align: center;
}

.w-full {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
