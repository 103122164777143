.panel {
  width: 100%;
  background-color: #fff;
  border: 1px solid $darker;
  border-radius: 5px;
  box-sizing: border-box;
  .header {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid $darker;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      h3 {
        font-size: 18px;
        color: $dark-200;
        letter-spacing: .5px;
        text-align: left;
      }
      h4 {
        font-size: 12px;
        color: $dark-200;
        letter-spacing: .5px;
        text-align: left;
        margin-top:10px;
      }
      &.center {
        h3, h4 {
          text-align: center;
        }
      }
    }
  }
  .content {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 14px;
  } 
  .footer {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
  }
}

.panel + .panel {
  margin-top: 20px;
}

.theme-dark {
  .panel {
    background-color: $dark-100;
    .header {
      border-color: rgba(255, 255, 255, 0.1);
      h3 {
        color: #fff;
      }
    }
  }
}